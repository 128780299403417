@font-face {
    font-family: 'playfair Display';
    src: url('../public/fonts/playfairDisplay/PlayfairDisplay-VariableFont_wght.ttf');
  }
  @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');

:root{
    --primary-color: hsl(30, 100%, 50%);
    --secondary-color: #ff7401;
    --tertiary-color: #ff5e14;
    --heading-color: #0b2727;
    --text-color:#6e7074;
    --text-color-white:#fff;
    --main-font:"Montserrat",sans-serif;
    --subtitle-font:"Island Moments", cursive;
    /* Primary */
    --var-soft-blue: hsl(215, 51%, 70%);
    --var-cyan: hsl(178, 100%, 50%);
    /* Neutral */
    --var-main-darkest: hsl(217, 54%, 11%);
    --var-card-dark: hsl(216, 50%, 16%);
    --var-line-dark: hsl(215, 32%, 27%);
    --var-lightest: white;
    /* Fonts */
    --var-heading: normal normal 600 1.5em/1.6em 'Outfit', sans-serif;
    --var-small-heading: normal normal 400 1em/1em 'Outfit', sans-serif;
    --var-para: normal normal 300 1em/1.55em 'Outfit', sans-serif;
}
body {
    background-color: var(--var-main-darkest);
}
* {
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
h2 {
    font: var(--var-small-heading);
    color: var(--var-lightest);
}
p {
    font: var(--var-para);
    color: var(--var-soft-blue);
} 
.main-color {
    color: var(--secondary-color)
}
::selection{background-color:var(--secondary-color);}
.text-gradient{
    background:linear-gradient(to right,var(--secondary-color) 20%, var(--heading-color) 70% );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-gradient2{
    background:linear-gradient(to right,var(--secondary-color) 0%, var(--text-color-white) 100% );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}
.service-arrow{
    .slick-next, .slick-prev{
        position: absolute;
        top: 110%;
        width: 100%;
    }
    .slick-prev:before, .slick-next:before {
        font-family: 'slick';
        font-size: 40px;
        padding-left: 20px;
        line-height: 1;
        opacity: 1;
        color: var(--text-color-white);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
.home-image {
    border-bottom-left-radius:50px ;
    border-top-right-radius:50px ;
    border: 1px solid var(--secondary-color);
	margin: 0;
	padding: 0;
	overflow: hidden;
    
}
/* lazy-load-image-background blur lazy-load-image-loaded */
.lazy-load-image-background {
display: inline !important;
}
.home-detail-text{
    color: var(--text-color);
    opacity: 0.8;
}
.home-text{
    color: '#000';
}
.home-detail-text2{
    color: var(--text-color-white);
    opacity: 0.8;
}
.main-text{
    display: block;
    font-size: 3.5rem;
    font-weight: 600 !important;
}
body{
    background-image: url("./images/hero-bg.png");
    background-position: center center;
    margin: 0%;
}
.list-text-header{
    font-family: 'playfair display' , serif !important;
    color: var(--heading-color);
    font-optical-sizing: auto;
    font-size: 1.0rem;
    font-weight: 600 !important;
}
.service-side-main-text{
    font-size:2.2rem !important;
    font-weight: bolder !important;
}
.service-side-text{
    font-size: 0.875rem !important;
    color: var(--secondary-color);
}
@media only screen and (min-width: 0px ) and (max-width: 373px ){
    .home-image {
        width: 110px;
        height: 200px;
    }
    .main-text{
        font-size: 1.8rem !important;
    }    
  }
  @media only screen and (min-width: 374px ) and (max-width: 899px ){
    .home-image {
        width: 110px;
        height: 200px;
    }
    .main-text{
        font-size: 2.3rem !important;
    }    
  }
  @media only screen and (min-width: 900px ) and (max-width: 1199px ){
    .home-image {
        width: 120px;
        height: 250px;
    }
    .main-text{
        font-size: 2.75rem !important;
    }    
  }
/* Zoom Out*/
.home-image-container .home-image img {
	transform: scale(1.2);
    width: 100%;
	height: 100%;
    object-fit: cover;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
@keyframes anim1{
    0%,
    100%{
        transform: translateY(2%);

    }
    50%{
        transform: translateY(-2%);

    }
}
@keyframes anim2{
    0%,
    100%{
        transform: translateY(2%);


    }
    50%{
        transform: translateY(-2%);


        
    }
}
@keyframes anim3{
    0%,
    100%{
        transform: translateY(2%);

    }
    50%{
        transform: translateY(-2%);


    }
}
.home-image-container:nth-child(1){
    animation: anim1 6s ease-in-out infinite forwards;
}
.home-image-container:nth-child(2){
    animation: anim2 6s ease-in-out infinite forwards;
    animation-delay: 3s;
}
.home-image-container:nth-child(3){
    animation: anim3 6s ease-in-out infinite forwards;
}
.home-image-container .home-image:hover img {
	transform: scale(1.05);
	transition: transform 0.3s ease;
}
.badge{
    background-color: var(--secondary-color);
    padding: 2px 10px;
    width: fit-content;
    text-align: center;
}
body {
    font-family: var(--main-font);
}
h1,
h2,
h3,
h5,
h6 {
    color: var(--heading-color);
}
a {
    text-decoration: none;
    color: unset;
}
ul {
    list-style: none;
}
.primary-btn {
    color: var(--secondary-color) !important;
    background: #fff !important;
    border: none !important;
    border-radius: 50px !important;
}
.primary-btn:hover{
    background: var(--secondary-color) !important;
    color: #fff !important;
}
.secondary-btn {
    background: transparent !important;
    color: #0b2727 !important;
    border: none !important;
    font-weight: 500 !important;
}
.secondary-btn a {
    font-size: 1.1rem;
    text-decoration: none;
    color: var(--heading-color);
}
.secondary-btn a:hover {
    color: inherit;
}
.scroll-progress{
    position: fixed;
    top:0;
    left: 24px;
    right: 24px;
    height: 4px;
    background: var(--secondary-color);
    transform-origin: 50%;
    z-index: 1000;
}
.text-animation{
    position: relative;
    text-transform: uppercase;
}
.text-animation::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: #07233f;
    border-right: 2px solid #ff7401;
    overflow: hidden;
    animation: animate 12s linear infinite;
}
.text-animation-sm-screen{
    position: relative;
    text-transform: uppercase;
}
.text-animation-sm-screen::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    color: #ff7401;
    border-right: 2px solid #ff7401;
    overflow: hidden;
    animation: animate 12s linear infinite;
}
@keyframes animate{
    0%,10%,100%{
        width: 0;
    }    
    50%,90%{
        width: 100%;
    }
}