.component-background{
	background-image: url('./images/background/strip-background.jpg');
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; 
  }
  
  .custom_heading-container h4 {
	color: #ffffff;
	font-weight: 700;
	position: relative;
  }
  
  .custom_heading-container h4::before {
	content: "";
	width: 60px;
	height: 3px;
	position: absolute;
	bottom: -2px;
	background-color: #6bd1bd;
  }
  
  .custom_heading-container h4::after {
	content: "";
	width: 10px;
	height: 10px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
	background-color: #fb1004;
	border-radius: 100%;
  }

  .custom_heading-container:hover {
	opacity: 0.4;
  }